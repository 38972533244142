'use strict';

function reactStart(container, content, value) {
	//content = new Map(Object.entries(content));
	//value = new Map(Object.entries(value));
	
	ReactDOM.render((
    	    <div>
    	        <UxrOverlay />
    	        <UrxPreselector />
    	    </div>
	    ),
		container[0]
	);
}

/*******************************************************************************************/
class UrxPreselector extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			'userNickname': null,
		};
		this.onJQueryMount = this.onJQueryMount.bind(this);
		this.onNicknameSubmit = this.onNicknameSubmit.bind(this);
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		if (!this.state.userNickname) {
			return (
				<div className="uiPanel ui_topLevel fullScreenCover UrxMain" ref={this.onJQueryMount}>
					<div style={{padding:'4em'}}>
						<div>Bitte Benutzername bitte eingeben:</div>
						<div><input type="text" /></div>
						<div><input type="button" value="weiter..." onClick={this.onNicknameSubmit} /></div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
    	        	<UxrOverlay />
					<UxrMain />
				</div>
			);
		}
	}
	
	/*---------------------------------------------------------------------*/
	onNicknameSubmit(event) {
		var userNickname = this.jQ.find('input[type="text"]').val();
		COOKIES.write('userNickname', userNickname);
		this.updateState();
	}
	
	/*---------------------------------------------------------------------*/
	componentDidMount() {
		this.updateState();
	}
	
	/*---------------------------------------------------------------------*/
	updateState() {
		this.setState({
			'userNickname': COOKIES.read('userNickname', '"', ''),
		});
	}
	
	/*---------------------------------------------------------------------*/
	onJQueryMount(node) {
		this.jQ = jQuery(node);
	}
	
	/*---------------------------------------------------------------------*/
	
} //class

/*******************************************************************************************/
class UxrMain extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dataImage: null,
			editedDI: [],
		};
		
		this.key = IdGenerator.produce('urxMain_');
		this.sharedContext = {
			list: {},
			editor: {},
			focusPoint: null,
			focusSemaphore: true,
		};
		
		this.onJQueryMount = this.onJQueryMount.bind(this);
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		let o = this;
		
		let listPanes = [];
		if (this.state.dataImage !== null) {
			let listDIitems = this.state.dataImage.values.data.entityTypes.getAll();
			
			let peerCount = _.size(listDIitems);
			let peerPosition = -1;
			
			_.forEach(listDIitems, function(listItem) {
				let listNodeId = listItem.nodeId;
				peerPosition++;
				
				o.sharedContext.list[listNodeId] = o.sharedContext.list[listNodeId] || {
					peerCount: peerCount,
					peerPosition: peerPosition,
					componentWillUnmount: _.bind(o.list_componentWillUnmount, o, listNodeId),
					onJQueryMount: _.bind(o.list_onJQueryMount, o, listNodeId),
					focusGain: _.bind(o.list_focusGain, o, listNodeId),
					focusLoose: _.bind(o.list_focusLoose, o, listNodeId),
				};
				
				let eachProps = _.defaults({},
					{
						sharedContext: o.sharedContext.list[listNodeId],
						nodeId: listNodeId,
						key: 'listPane_for_nodeId_'+ listNodeId,
						onEditWish: _.bind(o.onEditWish, o, listNodeId),
					}
				);
				
				let ListType = (E.fileListIds.indexOf(listNodeId) === -1)
				    ? UxrGpListFrame_input
				    : UxrGpListFrame_file
			        ;
				
				listPanes.push(
					<ListType {...eachProps} />
				);
			});
		} //listPanes
		
		let editorPanes = [];
		_.forEach(this.state.editedDI, function(editContext) {
			let prevJQ = o.sharedContext.editor.jQ;
			o.sharedContext.editor = _.defaults({},
				editContext,
				{
					jQ: prevJQ,
					onJQueryMount: _.bind(o.editor_onJQueryMount, o),
					focusGain: _.bind(o.editor_focusGain, o),
					focusLoose: _.bind(o.editor_focusLoose, o),
					onEditorSave: _.bind(o.onEditorSave, o),
					onEditorClose: _.bind(o.onEditorClose, o),
					onEditorDelete: _.bind(o.onEditorDelete, o),
				}
			);
			
			let eachProps = _.defaults({},
				//{
				//	listNodeId: editContext.listNodeId,
				//	itemNodeId: editContext.itemNodeId,
				//},
				{
					key: 'editPane_for_nodeId_'+ editContext.itemNodeId,
					sharedContext: o.sharedContext.editor,
				}
			);
			editorPanes.push(
				<UxrGpEdit {...eachProps} />
			);
		}); //editorPanes
		
		return (
			<div className="uiPanel ui_topLevel fullScreenCover UrxMain" ref={this.onJQueryMount}>
				<input type="text" placeholder="user" className="flowRight fontMono size09 " onChange={this.onUserChange} />
				<input type="text" placeholder="filter" className="flowRight fontMono size09 uiFilter " />
				<div className="uiContent">
					<div className="ui_topLevel_entityLists">
						{listPanes}
					</div>
					<div className="ui_topLevel_modelEditors">
						{editorPanes}
					</div>
				</div>
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	componentDidMount() {
		this.updateState();
	}
	
	/*---------------------------------------------------------------------*/
	updateState() {
		let o = this;
		E.updateController.removeListeners(o.key);
		
		E.localCache.dataImage.get(E.zeroNodeId).then(function(zeroDataImage) {
			E.updateController.registerListener(o.key, o.updateListener);
			o.setState({
				'dataImage': zeroDataImage,
			});
		});
	}
	
	/*---------------------------------------------------------------------*/
	updateListener(messages) {
		messages = messages.filter(function(message) {
			return (message.nodeId === E.zeroNodeId);
		});
		
		if (messages.length) {
			this.updateState();
		}
	}
	
	/*---------------------------------------------------------------------*/
	onJQueryMount(node) {
		this.jQ = jQuery(node);
		
		var userNickname = COOKIES.read('userNickname', '"', '');
		COOKIES.write('userNickname', userNickname);
		this.jQ.find('input[placeholder="user"]').val(userNickname);
	}
	
	/*---------------------------------------------------------------------*/
	onEditWish(listNodeId, itemNodeId) {
		let eqTest = function(v) { 
			return true
				&& v.listNodeId === listNodeId 
				&& v.itemNodeId === itemNodeId
				;
		}
		
		let newEntry = {
			listNodeId: listNodeId,
			itemNodeId: itemNodeId,
		};
		
		let o = this;
		
		this.setState((prevState, props) => {
            if (o.focusPoint) {
                let focusCtx = _.get(o.sharedContext, o.focusPoint);
                focusCtx.focusLoose();
            }
            
			let contains = _.filter(prevState.editedDI, eqTest);
			if (contains.length !== 0) {
				return;
				
			} else {
				//if (prevState.editedDI.length) {
				//	o.onEditorClose("dont-cleanup");
				//}
				return {
					editedDI: [newEntry],
				};
			}
		});
	}
	
	/*---------------------------------------------------------------------*/
	onEditorSave() {
		if (!E.updateController.attemptNewUpdate("store data-set")) {
			return;
		}
		
		let context = this.sharedContext.editor;
		let o = this;
		
		E.intermediateLayer
			.dataImage.clone(context.itemDI)
			.then(function(dataImage) {
				dataImage.values.data.userInput = context.value;
				console.log('cloned & new data', dataImage.values.id, dataImage);
				return dataImage;
			})
			.then(function(dataImage) {
				console.log('attempting to store', dataImage.values.id);
				return E.intermediateLayer.dataImage.store(dataImage);
			})
			.then(function(dataImage) {
				console.log('stored', dataImage.values.id);
				E.updateController.updateMessages.push({'nodeId': dataImage.node.id, 'dataImage': dataImage});
				E.updateController.finalizeUpdate();
			})
		;
	}
	
	/*---------------------------------------------------------------------*/
	onEditorClose() {
		let context = this.sharedContext.editor;
		let eqTest = function(v) { 
			return true
				&& v.listNodeId === context.listNodeId 
				&& v.itemNodeId === context.itemNodeId
				;
		}
		
		this.setState((prevState, props) => {
			
			let nextEditedDI = _.filter(prevState.editedDI, _.negate(eqTest));
			if (nextEditedDI.length !== prevState.editedDI) {
				return {
					editedDI: nextEditedDI,
				};
			//} else {
			//	return {};
			}
		});
	}
	
	/*---------------------------------------------------------------------*/
	onEditorDelete() {
        if (!E.updateController.attemptNewUpdate("delete data-set")) {
            return;
        }
        
        let context = this.sharedContext.editor;
        let o = this;
        
        E.intermediateLayer
            .dataImage.clone(context.listDI)
            .then(function(dataImage) {
                console.log(Object.keys(dataImage.values.data.items._dataImages).join(','));
                dataImage.values.data.items.remove(context.itemDI.node.id);
                console.log(Object.keys(dataImage.values.data.items._dataImages).join(','));
                return dataImage;
            })
            .then(function(dataImage) {
                console.log('attempting to store', dataImage.values.id);
                return E.intermediateLayer.dataImage.store(dataImage);
            })
            .then(function(dataImage) {
                console.log('deletedNodeId / storedValuesId', context.itemDI.node.id, dataImage.values.id);
                o.setState({
                    editedDI: [],
                });
                E.updateController.updateMessages.push({'nodeId': dataImage.node.id, 'dataImage': dataImage});
                E.updateController.finalizeUpdate();
            })
        ;
	}
	
	/*---------------------------------------------------------------------*/
	onUserChange(event) {
		var userNickname = event.target.value;
		COOKIES.write('userNickname', userNickname);
	}
	
	/*---------------------------------------------------------------------*/
	list_componentWillUnmount(listId) {
		delete this.sharedContext[listId];
	}
	
	/*---------------------------------------------------------------------*/
	list_onJQueryMount(listId) {
		let ctx = this.sharedContext.list[listId];
		let jQ = ctx.jQ;
		
		let cnt = ctx.peerCount;
		let idx = ctx.peerPosition;
		
		let containerHeight = jQ.parent().height();
		let contentHeight = containerHeight- ((cnt-1)* 10);
		let height = contentHeight/cnt;
		let top = height*idx+ 10+ (idx-1)*10;
		
		jQ.css({
			'top': top+ 'px',
			'height': height+ 'px'
		});
		
		jQ.on('mouseenter click', _.bind(this.list_mouseenter, this, listId));
	}
	
	/*---------------------------------------------------------------------*/
	list_mouseenter(listId) {
		if (!this.sharedContext.focusSemaphore) {
			return;
		}
		
		let ctx = this.sharedContext.list[listId];
		let jQ = ctx.jQ;
		
		if (this.focusPoint) {
			let focusCtx = _.get(this.sharedContext, this.focusPoint);
			if (focusCtx === ctx) {
				return;
			}
			focusCtx.focusLoose();
		}
		
		ctx.focusGain();
	}
	
	/*---------------------------------------------------------------------*/
	list_focusGain(listId) {
		let ctx = this.sharedContext.list[listId];
		let jQ = ctx.jQ;
		
		this.focusPoint = ['list', listId];
		this.sharedContext.focusSemaphore = false;
		
		jQ.addClass('ui_entityListFocused ui_entityListProcess');
		
		let o = this;
		setTimeout(
			function() {
				jQ.removeClass('ui_entityListProcess');
				o.sharedContext.focusSemaphore = true;
			}, 
			600);
	}
	
	/*---------------------------------------------------------------------*/
	list_focusLoose(listId) {
		let ctx = this.sharedContext.list[listId];
		let jQ = ctx.jQ;
		
		jQ.removeClass('ui_entityListFocused');
		jQ.addClass('ui_entityListProcess');
		this.focusPoint = null;
		this.sharedContext.focusSemaphore = false;
		
		let o = this;
		setTimeout(
			function() {
				jQ.removeClass('ui_entityListProcess');
				o.sharedContext.focusSemaphore = true;
			}, 
			600);
	}
	
	/*---------------------------------------------------------------------*/
	editor_onJQueryMount() {
		let ctx = this.sharedContext.editor;
		let jQ = ctx.jQ;
		
		jQ.on('mouseenter', _.bind(this.editor_mouseenter, this));
	}
	
	/*---------------------------------------------------------------------*/
	editor_mouseenter() {
		if (this.focusPoint) {
			let focusCtx = _.get(this.sharedContext, this.focusPoint);
			focusCtx.focusLoose();
		}
		
		this.focusPoint = null;
	}
	
	/*---------------------------------------------------------------------*/
	editor_focusGain(listId) {
	}
	
	/*---------------------------------------------------------------------*/
	editor_focusLoose(listId) {
	}
	
} //class
